import {useContext} from 'react';
import Navbar from '../Componets/Navbar';
import CartContext from '../Context/CartContext';
import { Link } from 'react-router-dom';

const Cart = () => {

  const {cart,setCart, total} = useContext(CartContext);

  const removeItem =(idx) => {

    let temp = [...cart];
    temp.splice(idx,1);
    setCart(temp);

  }

  // const total = cart.reduce((acc,curVal) => {

  //   return acc + curVal.qty*curVal.price;

  // },0)

  return (
    <div className="main-content-wrapper d-flex clearfix">

    <Navbar/>
    <div className="cart-table-area section-padding-100">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="cart-title mt-50">
                            <h2>Shopping Cart</h2>
                        </div>

                        <div className="cart-table clearfix">
                        {cart.length > 0
                        ?
                        <table className="table table-responsive">
                                <thead>
                                    <tr>
                                      <th className="col-md-4">Image</th>
                                      <th className="col-md-2">Name</th>
                                      <th className="col-md-2">Unit Price</th>
                                      <th className="col-md-1">Quantity</th>
                                      <th className="col-md-2">Price</th>
                                      <th className="col-md-1">Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {cart.map((item,idx) => {
                                    
                                  return(
                                    <tr key={item.id}>
                                        <td className="cart_product_img col-md-4">
                                          <img src={`https://furniture.boyanvulic.com/img/product-img/${item.img}`} alt="Product" />
                                        </td>
                                        <td className="cart_product_desc col-md-2">
                                            <span>{item.name}</span>
                                        </td>
                                        <td className="price col-md-2">
                                            <span>${item.price}</span>
                                        </td>
                                        <td className="qty col-md-1">
                                               <span>{item.qty}</span>
                                        </td>
                                        <td className="qty col-md-2">
                                                <span>${item.qty*item.price}</span>
                                        </td>
                                        <td className="col-md-1">
                                        <button  className="btn btn-danger" onClick={()=> {removeItem(idx)}}>x</button>
                                        </td>
                                    </tr>
                                  )
                                })}
                                </tbody>
                            </table>
                            :
                            <h3>There are no selected items in the cart</h3>
                        
                        }
                            
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="cart-summary">
                            <h5>Cart Total</h5>
                            <ul className="summary-table">
                                <li><span>delivery:</span> <span>Free</span></li>
                                <li><span>total:</span> <span>${total}</span></li>
                            </ul>
                            <div className="cart-btn mt-100">
                                <Link to="/checkout" className="btn amado-btn w-100">Checkout</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
  )
}

export default Cart;