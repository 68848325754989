import {useContext} from 'react';
import {Link} from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import CartContext from '../Context/CartContext';
const Navbar = () => {
    const {cart,setCart, total} = useContext(CartContext);

  return (
    <>
        {/* <div className="mobile-nav"> */}
           
            {/* <div className="amado-navbar-brand">
                <Link to="/"><img src={"img/core-img/logo.png"} alt=""/></Link>
            </div>
           
            <div className="amado-navbar-toggler">
                <span></span><span></span><span></span>
            </div> */}
            <nav className="mobile-nav navbar navbar-expand-lg justify-content-end">
            <Link to="/"><img src={"img/core-img/logo.png"} alt=""/></Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNavContent" aria-controls="mainNavContent" aria-expanded="false" aria-label="Toggle navigation"><i className="fa fa-bars"></i></button>
                            <div className="collapse navbar-collapse" id="mainNavContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/shop">Shop</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/cart">Cart</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/checkout">Checkout</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/admin">Admin</Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
        {/* </div> */}

       
        <header className="header-area clearfix">
            
            <div className="nav-close">
                <i className="fa fa-close" aria-hidden="true"></i>
            </div>
            <div className="logo">
                <Link to="/"><img src={"../img/core-img/logo.png"} alt=""/></Link>
            </div>
            <nav className="amado-nav">
                <ul>
                    <li><NavLink exact to="/" activeClassName="active">Home</NavLink></li>
                    <li><NavLink to="/shop" activeClassName="active">Shop</NavLink></li>
                    <li><NavLink to="/checkout" activeClassName="active">Checkout</NavLink></li>
                    <li><NavLink to="/admin" activeClassName="active">Admin</NavLink></li>
                </ul>
            </nav>
            
            <div className="cart-fav-search mb-100">
                <Link to="/cart" className="cart-nav"><img src="../img/core-img/cart.png" alt=""/> Cart <span>({cart.length})</span></Link>
            
            </div>
            <div className="social-info d-flex justify-content-between">
                <a href="#"><i className="fa fa-pinterest" aria-hidden="true"></i></a>
                <a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
            </div>
        </header>
     
    </>
  )
}

export default Navbar;