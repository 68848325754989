import React from 'react';
import Navbar from '../Componets/Navbar';

const NotFound = () => {
  return (
    <div className="main-content-wrapper d-flex clearfix align-items-center"> 
     <Navbar/>
     <h1>Error 404- Page is not found!</h1>
     </div>
  )
}

export default NotFound;