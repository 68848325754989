import { useContext, useState } from 'react';
import Navbar from '../Componets/Navbar';
import { Link } from 'react-router-dom';
import ProductsContext from '../Context/ProductsContext.js';

const Shop = () => {
    const { products } = useContext(ProductsContext);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 4; 

    // Funkcija za renderovanje proizvoda po kategorijama
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setCurrentPage(1); // Reset to first page when category changes
    };

    // Funkcija za menjanje stranica paginacije
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

     // Funkcija za filtriranje proizvoda po kategorijama sa paginacijom
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = selectedCategory ?
        products.filter(prod => prod.category === selectedCategory).slice(indexOfFirstProduct, indexOfLastProduct) :
        products.slice(indexOfFirstProduct, indexOfLastProduct);

    // Izracunavanje ukupnog broja stranica
    const totalPages = Math.ceil((selectedCategory 
        ? 
        products.filter(prod => prod.category === selectedCategory).length : products.length) / productsPerPage);

    // Funkcija za renderovanje svih stranica
    const renderAllProducts = () => {
        setSelectedCategory(null);
        setCurrentPage(1); 
    };

    return (
        <div className="main-content-wrapper d-flex clearfix">
            <Navbar />
            <div className="shop_sidebar_area">
                <div className="widget catagory mb-50">
                    <h6 className="widget-title mb-30">Categories</h6>
                    <div className="catagories-menu">
                        <ul>
                            <li className={selectedCategory === null ? 'active' : ''}>
                                <button onClick={ renderAllProducts}>All</button>
                            </li>
                            <li className={selectedCategory === 'chairs' ? 'active' : ''}>
                                <button onClick={() => handleCategoryClick('chairs')}>Chairs</button>
                            </li>
                            <li className={selectedCategory === 'tables' ? 'active' : ''}>
                                <button onClick={() => handleCategoryClick('tables')}>Tables</button>
                            </li>
                            <li className={selectedCategory === 'accesories' ? 'active' : ''}>
                                <button onClick={() => handleCategoryClick('accesories')}>Accessories</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="amado_product_area section-padding-100">
                <div className="container-fluid">
                    {/* Products */}
                    <div className="row">
                    {currentProducts.map(product => {
                              return(
                                <div className="col-12 col-sm-6 col-md-12 col-xl-6" key={product.id}>
                                <div className="single-product-wrapper">
                          
                          <div className="product-img">
                            <Link to={`/single/${product.id}`}>
                              <img src={`https://furniture.boyanvulic.com/img/product-img/${product.img[0]}`} alt=""/>
                           
                              <img className="hover-img" src={`https://furniture.boyanvulic.com/img/product-img/${product.img[1]}`} alt=""/>
                              </Link>
                          </div>

                          <div className="product-description d-flex align-items-center justify-content-between">
                             
                              <div className="product-meta-data">
                                  <div className="line"></div>
                                  <p className="product-price">${product.price}</p>
                                  <a href="product-details.html">
                                      <h6>{product.name}</h6>
                                  </a>
                              </div>
                              
                              <div className="ratings-cart text-right">
                                  <div className="cart">
                                      <Link to={`/single/${product.id}`} data-toggle="tooltip" data-placement="left" title="Add to Cart"><img src="img/core-img/cart.png" alt=""/></Link>
                                  </div>
                              </div>
                          </div>
                      </div>
                      </div>
                              )
                            })}
                    </div>
                    {/* Pagination */}
                    <div className="row">
                        <div className="col-12">
                            <nav aria-label="navigation">
                                <ul className="pagination justify-content-end mt-50">
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <li className={`page-item ${currentPage === i + 1 ? 'active' : ''}`} key={i}>
                                            <button className="page-link" onClick={() => handlePageChange(i + 1)}>{i + 1}</button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Shop;