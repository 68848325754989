import React from 'react';
import{ Routes, Route} from 'react-router-dom';

//Components

import Discount from "./Componets/Discount";
import Footer from "./Componets/Footer";


// Pages

import Home from './Pages/Home';
import Shop from './Pages/Shop';
import Cart from './Pages/Cart';
import Checkout from './Pages/Checkout';
import Admin from './Pages/Admin';
import NotFound from './Pages/NotFound';
import Single from './Pages/Single';

const App = () =>{
  return (
    <div>
    
     
     <Routes>
    <Route path='/' element={<Home/>} />
    <Route path='/shop' element={<Shop/>} />
    <Route path='/cart' element={<Cart/>} />
    <Route path='/checkout' element={<Checkout/>} />
    <Route path='/admin' element={<Admin/>} />
    <Route path='/*' element={<NotFound/>} />
    <Route path='/single/:id' element={<Single/>} />  
    
    </Routes>
   
   <Discount/>
    <Footer/>

   
    
    </div>
  );
}

export default App;
