import { createContext, useState} from "react";

const CartContext = createContext();

export const CartProvider = ({children}) => {

    const [cart, setCart] = useState([]);

    const total = cart.reduce((acc, curVal) => acc + curVal.qty * curVal.price, 0);


return(

<CartContext.Provider value = {{cart, setCart, total}}>
    {children}
</CartContext.Provider>

)
}
export default CartContext;