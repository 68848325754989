import { createContext, useEffect, useState} from "react";

const ProductsContext = createContext();

export const ProductsProvider = ({children}) => {

    const [products, setProducts] = useState([]);

    useEffect(() => {
      fetch('https://furniture.boyanvulic.com/fetch_data.php')
      .then(res => res.json())
      .then(resRow => setProducts(resRow))
      .catch(err => console.log(err))
  
    },[])

return(

<ProductsContext.Provider value = {{products, setProducts}}>
    {children}
</ProductsContext.Provider>

)
}
export default ProductsContext;