import { useContext, useState, useEffect } from 'react';
import Navbar from '../Componets/Navbar';
import ProductsContext from '../Context/ProductsContext';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
  const { products, setProducts } = useContext(ProductsContext);
  const [editMode, setEditMode] = useState({ mode: false, id: null });
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [desc, setDesc] = useState('');
  const [category, setCategory] = useState('');
  const [qty, setQty] = useState('');
  const [img, setImg] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    fetch('https://furniture.boyanvulic.com/fetch_data.php')
      .then(res => res.json())
      .then(resRow => setProducts(resRow))
      .catch(err => console.log(err));
  };

  const setEditProduct = (id) => {
    setEditMode({ mode: true, id });
    let product = products.find(prod => prod.id === id);
    if (product) {
      setName(product.name);
      setPrice(product.price);
      setDesc(product.desc);
      setCategory(product.category);
      setQty(product.qty);
    }
  };

  const editProduct = (event) => {

    event.preventDefault();
  
  const categoryString = String(category);

  const data = { id: editMode.id, name, price, desc, category: categoryString, qty };

  fetch(`https://furniture.boyanvulic.com/update_product.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(response => {
      if (response.success) {
        cancelEditing();
        fetchProducts();
      } else {
        console.error('Update failed:', response.error);
      }
    })
    .catch(err => console.log(err));
};
   
  const cancelEditing = () => {
    setEditMode({ mode: false, id: null });
    setName('');
    setPrice('');
    setDesc('');
    setCategory('');
    setQty('');
  };

  const removeProduct = (id) => {
    fetch(`https://furniture.boyanvulic.com/delete_product.php`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id })
    })
      .then(res => res.json())
      .then(() => fetchProducts())
      .catch(err => console.log(err));
  };

  const addProduct = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('price', price);
    formData.append('desc', desc);
    formData.append('category', category);
    formData.append('qty', qty);
     for (let i = 0; i < img.length; i++) {
      formData.append('img[]', img[i]);
    }

    fetch('https://furniture.boyanvulic.com/create_product.php', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(() => {
        fetchProducts();
        setName('');
        setPrice('');
        setDesc('');
        setCategory('');
        setQty('');
        setImg(null);
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="main-content-wrapper d-flex clearfix">
      <Navbar />
      <div className="cart-table-area section-padding-100">
        <div className="container-fluid">
          {editMode.mode ? 
            <>
              <h3>Edit Product</h3>
              <form onSubmit={editProduct}>
                <div className="mb-3">
                  <input type="text" name="name" value={name} className="form-control" placeholder='Name' onChange={(event) => setName(event.target.value)} />
                </div>
                <div className="mb-3">
                  <input type="number" name="price" value={price} className="form-control" placeholder='Price' onChange={(event) => setPrice(event.target.value)} />
                </div>
                <div className="mb-3">
                  <textarea type="text" name="desc" value={desc} className="form-control" rows="3" placeholder='Description' onChange={(event) => setDesc(event.target.value)}></textarea>
                </div>
                <div className="mb-3">                
                  <input type="text" name="category" value={category} className="form-control" placeholder='Category' onChange={(event) => setCategory(event.target.value)} />
                </div>
                <div className="mb-3">
                  <input type="number" name="qty" value={qty} className="form-control" placeholder='Qty' onChange={(event) => setQty(event.target.value)} />
                </div>
                <input type="submit" value="Edit" className="btn btn-primary" />
              </form>
              <br />
              <button className="btn btn-danger" onClick={cancelEditing}>Cancel</button>
            </>
           : 
            <>
              <h3>New Product</h3>
              <form onSubmit={addProduct} encType="multipart/form-data">
                <div className="mb-3">
                  <input type="text" name="name" value={name} className="form-control" placeholder='Name' onChange={(event) => setName(event.target.value)} />
                </div>
                <div className="mb-3">
                  <input type="number" name="price" value={price} className="form-control" placeholder='Price' onChange={(event) => setPrice(event.target.value)} />
                </div>
                <div className="mb-3">
                  <textarea type="text" name="desc" value={desc} className="form-control" rows="3" placeholder='Description' onChange={(event) => setDesc(event.target.value)}></textarea>
                </div>
                <div className="mb-3">
                  <input type="text" name="category" value={category} className="form-control" placeholder='Category' onChange={(event) => setCategory(event.target.value)} />
                </div>
                <div className="mb-3">
                  <input type="number" name="qty" value={qty} className="form-control" placeholder='Qty' onChange={(event) => setQty(event.target.value)} />
                </div>
                <div className="mb-3">
                    <input type="file" className="form-control" name="img" multiple onChange={(event) => setImg(Array.from(event.target.files))} /> 
                </div>
                <input type="submit" value="Add" className="btn btn-primary" />
              </form>
            </>
          }
          <hr />
          <div className="row table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="col-md-1">Image</th>
                  <th className="col-md-2">Name</th>
                  <th className="col-md-2">Price</th>
                  <th className="col-md-2">Category</th>
                  <th className="col-md-1">Qty</th>
                  <th className="col-md-2">View</th>
                  <th className="col-md-1">Edit</th>
                  <th className="col-md-1">Delete</th>
                </tr>
              </thead>
              <tbody>
                {products.map((item, idx) => (
                  <tr key={item.id}>
                    <td className="cart_product_img col-md-1">
                      <img src={`https://furniture.boyanvulic.com/img/product-img/${item.img[0]}`} alt="Product" width="80%" height="auto"/>
                    </td>
                    <td className="cart_product_desc col-md-2">
                      <span>{item.name}</span>
                    </td>
                    <td className="price col-md-2">
                      <span>${item.price}</span>
                    </td>
                    <td className="category col-md-2">
                      <span>{item.category}</span>
                    </td>
                    <td className="qty col-md-1">
                      <span>{item.qty}</span>
                    </td>
                    <td className="qty col-md-2">
                      <button className="btn btn-info" onClick={() => navigate(`/single/${item.id}`)}>View</button>
                    </td>
                    <td className="qty col-md-1">
                      <button className="btn btn-warning" onClick={() => setEditProduct(item.id)}>Edit</button>
                    </td>
                    <td className="col-md-1">
                      <button className="btn btn-danger" onClick={() => removeProduct(item.id)}>X</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
