import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {ProductsProvider} from './Context/ProductsContext';
import { CartProvider } from './Context/CartContext';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
 
  <ProductsProvider>
    <CartProvider>

    <App />
    
    </CartProvider>
    </ProductsProvider>
 
  </BrowserRouter>
  
);


