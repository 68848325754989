import {useParams,useNavigate} from 'react-router-dom';
import { useContext } from 'react';
import ProductsContext from '../Context/ProductsContext';
import CartContext from '../Context/CartContext';

import Navbar from '../Componets/Navbar';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Single = () => {

  const {products} = useContext(ProductsContext);
  const {cart, setCart} = useContext(CartContext);
  const params = useParams();
  const navigate =useNavigate();

// Kreiranje single proizvoda

  let product = products.filter(prod => {

    if(prod.id == params.id)
    return prod;

  })[0];

  //Kreiranje option menija
 
  let option = [];
  for (let i = 1; i < product.qty; i++) {
     option.push( <option type="number" className="qty-text" id="qty" name="quantity" key={i} value={i}>{i}</option>)
    
  }

  // Kreiranje forme za stavljanje proizvoda u korpu

  const addToCart = (event) => {
    event.preventDefault();

    cart.some(elem => {
        return elem.id === Number(params.id);
    })
    ?

    cart.filter(item => {
        if(item.id === Number(params.id)){
            return item;
        }
    })[0].qty += Number(event.target.selectQty.value)

    :
    setCart(cart => [...cart, {
        id: params.id,
        name: product.name,
        price: product.price,
        img: product.img[0],
        desc: product.desc,
        category: product.category,
        qty: Number(event.target.selectQty.value)

    }])

    navigate('/shop')
  }

  // Slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="main-content-wrapper d-flex clearfix">

    <Navbar/>
    <div className="single-product-area section-padding-100 clearfix">
            <div className="container-fluid">
                <div className="row" key={product.id}>
                    <div className="col-12 col-lg-7">
                   
                        <Slider {...sliderSettings}>
                            {product.img.map((image, index) => (
                                <div key={index}>
                                    <img src={`https://furniture.boyanvulic.com/img/product-img/${image}`} alt={`Product Image ${index + 1}`} />
                                </div>
                                ))}
                        </Slider>
                        
                        </div>  
                    <div className="col-12 col-lg-5">
                        <div className="single_product_desc">
                           <p></p>
                            <div className="product-meta-data">
                                <div className="line"></div>
                                <p className="product-price">${product.price}</p>
                                <a href="product-details.html">
                                   <h6>{product.name}</h6>
                                </a>
                              
                                 <div className="ratings-review mb-15 d-flex align-items-center justify-content-between">
                                    <div className="ratings">
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                    </div>
                                   
                                </div> 
                            
                                <p className="avaibility"><i className="fa fa-circle"></i> In Stock</p>
                            </div>

                            <div className="short_overview my-5">
                                <p>{product.desc}</p>
                            </div>

                         
                            <form className="cart clearfix" onSubmit={addToCart}>
                                <div className="cart-btn d-flex mb-50">
                                    <p>Qty</p>
                                    <select className="select" name='selectQty'>
                                        {option}
                                    </select>
                                    
                                </div>
                                <button type="submit" name="addtocart"  className="btn amado-btn">Add to cart</button>
                            </form>

                        </div>
                    </div>
                </div>
                </div>
        </div>
    
    </div>
  )
}

export default Single;